export const CACHE_EMAIL_CAMPAIGN_FORM_DATA = 'CACHE_EMAIL_CAMPAIGN_FORM_DATA';
export const RESET_EMAIL_CAMPAIGN_FORM_DATA = 'RESET_EMAIL_CAMPAIGN_FORM_DATA';

export const cacheEmailCampaignFormData = (formData) => ({
  type: CACHE_EMAIL_CAMPAIGN_FORM_DATA,
  formData,
});

export const resetEmailCampaignFormData = () => ({
  type: RESET_EMAIL_CAMPAIGN_FORM_DATA,
});

const initialState = {
  emailCampaignForm: {
    id: '',
    name: '',
    description: '',
    type: '',
    provider: '',
    languages: [],
    channel: '',
    tags: '',
    filterId: null,
    templateId: null,
    draft: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CACHE_EMAIL_CAMPAIGN_FORM_DATA:
      return {
        ...state,
        emailCampaignForm: action.formData,
      };

    case RESET_EMAIL_CAMPAIGN_FORM_DATA:
      return initialState;
    default:
      return state;
  }
}

export const CACHE_USER_DATA = 'CACHE_USER_DATA';

export const cacheUserData = (cacheUser) => ({
  type: CACHE_USER_DATA,
  cacheUser,
});

export default function reducer(
  state = {
    userInfo: {},
  },
  action
) {
  switch (action.type) {
    case CACHE_USER_DATA:
      return {
        ...state,
        userInfo: action.cacheUser,
      };
    default:
  }
  return state;
}

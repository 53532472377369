export const CACHE_TICKET_STATUSES = 'CACHE_TICKET_STATUSES';
export const CACHE_TICKET_PRIORITIES = 'CACHE_TICKET_PRIORITIES';
export const CACHE_AGENT_GROUPS = 'CACHE_AGENT_GROUPS';
export const CACHE_CAMPAIGN_TEMPLATE_CONFIG = 'CACHE_CAMPAIGN_TEMPLATE_CONFIG';

export const cacheTicketStatuses = (cacheStatuses) => ({
  type: CACHE_TICKET_STATUSES,
  cacheStatuses,
});

export const cacheTicketPriorities = (cachePriorities) => ({
  type: CACHE_TICKET_PRIORITIES,
  cachePriorities,
});

export const cacheTicketAgentGroups = (cacheAgentGroups) => ({
  type: CACHE_AGENT_GROUPS,
  cacheAgentGroups,
});

export const cacheCampaignTemplateConfig = (campaignTemplateConfig) => ({
  type: CACHE_CAMPAIGN_TEMPLATE_CONFIG,
  campaignTemplateConfig,
});

export default function reducer(
  state = {
    ticket: {
      ticketStatus: [],
      ticketPriorities: [],
      ticketAgentGroups: [],
    },
    campaign: {
      template: [],
    },
  },
  action
) {
  switch (action.type) {
    case CACHE_TICKET_STATUSES:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ticketStatus: action.cacheStatuses,
        },
      };
    case CACHE_TICKET_PRIORITIES:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ticketPriorities: action.cachePriorities,
        },
      };
    case CACHE_AGENT_GROUPS:
      return {
        ...state,
        ticket: {
          ...state.ticket,
          ticketAgentGroups: action.cacheAgentGroups,
        },
      };

    case CACHE_CAMPAIGN_TEMPLATE_CONFIG:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          template: action.campaignTemplateConfig,
        },
      };
    default:
      return state;
  }
}

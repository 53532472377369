import ThemeOptions from './ThemeOptions';
import CloverConfig from './CloverConfig';
import UserReducer from './UserReducer';
import CampaignReducer from './CampaignReducer';

import { combineReducers } from 'redux';

export default combineReducers({
  ThemeOptions,
  CloverConfig,
  UserReducer,
  CampaignReducer,
});

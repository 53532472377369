import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';
import reducers from "../reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

const initialState = {};

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['CloverConfig', 'UserReducer']
};

const persistor = persistReducer(persistConfig, reducers)

const middleware = [thunk];

const store = createStore(persistor, initialState, composeWithDevTools(applyMiddleware(...middleware)));

const persistingStore = persistStore(store);

export { persistingStore, store }
